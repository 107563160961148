@import url(https://fonts.googleapis.com/css?family=Saira+Stencil+One);

.home-page {
  position: relative;
}

.home-text {
  font-size: 1.2rem;
  color: #BFDBFE;
  width: 85%;
  margin-top: 1rem;
}

.home-content {
  display: flex;
  justify-content: between;
  align-items: center;
  height: 100vh;
  width: 100%;
  margin: auto;
  padding: 0 4rem;
}

.home-left {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
  padding-left: 4rem;
  font-size: 60px;
}

.home-right {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: left;
}

.home-page-bg {
  position: absolute;
  inset: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 90%;
  filter: brightness(0.7) blur(2px);
  z-index: -1;
}

.button-85 {
  padding: 0.4em 2em;
  margin-top: 1.5em;
  color: rgb(255, 255, 255);
  background: #3B82F6;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  width: 16rem;
  height: 4rem;
  font-size: 1.5rem;
  transition: all 400ms ease-in;
}

.button-85:hover {
  background-color: #1D283A;
}

.profile-text {
  color: #FBBF24;
  z-index: 0;
  display: inline;
  font-weight: 400;
  border-right: 4px solid #FBBF24;
  white-space: nowrap;
  animation: blinkTextCursor 500ms infinite;
  line-height: 0.5em;
}

@keyframes blinkTextCursor {
  0% {
    border-right-color: #FBBF24;
  }
  50% {
    border-right-color: #FBBF24;
  }
  51% {
    border-right-color: transparent;
  }
  100% {
    border-right-color: transparent;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.console-box {
  height: 13rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 10px;
  background-color: #1d283a;
  border-radius: 0.75rem;
  padding: 1.5rem;
  text-align: left;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
}

.console-head {
  display: flex;
  column-gap: 0.6rem;
}

.console-body {
  margin-top: 10px;
}

.console-head-dot {
  height: 1rem;
  width: 1rem;
  background-color: #78797a;
  border-radius: 50%;
}

.console-body-row {
  display: flex;
  margin: 0.5em 0;
  line-height: 1;
  font-size: 1.3em;
  text-align: center;
  vertical-align: center;
}

.console-body-text {
  margin-left: 1.2rem;
}

@media only screen and (max-width: 1000px) {
  /* Styles */
  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 4rem 0.5rem 0rem 0.5rem;
  }

  .home-left, .home-right {
    width: 100%;
    height: 30%;
    font-size: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .button-85 {
    width: 12rem;
  }

  .console-body-text {
    margin-left: 0.8rem;
    white-space: pre;
  }

  .console-box {
    font-size: 0.8rem;
    width: 22rem;
    height: 12rem;
    padding: 1.6rem 0.6rem 1.5rem 1.2rem;
    margin-right: 0;
  }

  .button-85 {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 370px) {
  .console-box {
    scale: 0.9;
  }
}