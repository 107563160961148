@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Raleway;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  border-radius: px;
  background: #c9cacc;
}

body::-webkit-scrollbar-track:hover {
  background: #78797a;
}

body::-webkit-scrollbar-thumb {
  background: #3d5585; 
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #01143b; 
}

.hands-light {
  background-color: rgb(240, 223, 205);
  height: 100vh;
  padding-top: 4rem;
}

.hands-dark {
  background-color: rgb(78, 74, 70);
  height: 100vh;
  padding-top: 4rem;
  color: white;
}

.editor-light {
  background-color: rgb(204, 209, 235);
  height: 100vh;
  padding-top: 4rem;
}

.editor-dark {
  background-color: rgb(15, 4, 60);
  height: 100vh;
  padding-top: 4rem;
  color: white;
}

@keyframes fade-l {
  0%{
    transform: translateX(-3%);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-d {
  0%{
    transform: translateY(10%);
    opacity: 1;
  }
  50%{
    transform: translateY(-2%);
    opacity: 1;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}

.navbar-box {
  background-color: #003675;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  z-index: 50;
  padding: 0 2rem;
  position: fixed;
  width: 100vw;
  top: 0;
  font-family: Raleway;
  transition: top 600ms;
}

.navbar-hidden {
  top: -72px;
}

.nav-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 8px 0;
}

.active-nav {
  background-color: #3B82F6;
  color: white !important;
}