  .about-box {
    padding: 4.5rem 0 2rem 0;
    background: linear-gradient(to bottom, #d4eaf5, #f3f4f6);
  }

  .container {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    width: full;
    height: full;
  }
  
  .container .c1 {
    width: 35%;
    display: flex;
    justify-content: center;
  }
  
  .container .c1 img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 22rem;
  } 
  
  .container .c2 {
    width: 65%;
    height: full;
    padding: 0px 20px;
    font-weight: 500;
  }
  
  .c2 h2 {
    font-size: 40px;
    margin-bottom: 1rem;
  }
  
  .c2 p {
    color: #4d4b4b;
    font-size: 20px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .vertical-line {
    height: 6rem;
    width: 0.2rem;
    background-color: grey;
  }

@media only screen and (max-width: 1000px) {
    .about-box {
      padding: 3rem 0 2rem 0;
    } 
    .container {
      flex-direction: column;
      justify-content: space-around;
      margin-top: 4rem;;
    }
    .container .c1, .container .c2 {
      width: 100%;
      align-items: center;
      text-align: center;
    }
    .container h2 {
      text-align: center;
      font-size: 2.2rem;
      margin-top: 2rem;
    }
    
    .container .c2 p {
      font-size: 1.1rem;
    }
    
    .vertical-line {
      height: 0.2rem;
      width: 6rem;
    }
}
  
  