@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

#contact {
    padding-bottom: 6em;
    background: #1c1d25;  
}

.contact-text {
    font-size: 3.5rem;
    text-align: center;
    background: white;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 40vh;
    width: 100vw;
    background-color: #1c1d25;
}

.name-container {
    margin-top: 2em;
    color: white;
    font-size: 3em;
    font-weight: 400;
    letter-spacing: 4px;
    text-align: center;
}

.copyright-section {
    color: white;
    font-size: 0.8rem;
    margin-top: 2rem;
}

@mixin transformScale($size: 1) {
    transform: scale($size);
    -ms-transform: scale($size);
    -webkit-transform: scale($size);
}

.social-container {
    width: 800px;
    text-align: center;
}

.social-icons {
    padding: 0;
    list-style: none;
    margin: 1em;
}
  
.social-icons li {
    display: inline-block;
    margin: 0.15em 1em;
    position: relative;
    font-size: 2em;
}

.social-icons i {
    color: #fff;
    position: relative;
    z-index: 1;
    transition: all 265ms ease-out;
}

.social-icons a {
    display: grid;
    place-items: center;
    width: 80px;
    height: 80px;
}  

.social-icons a:before {
    @include transformScale();
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 100%;
    display: block;
    background: linear-gradient(45deg, #00B5F5, #002A8F);
    transition: all 256ms ease-out;
}

.social-icons a:hover:before {
    transform: scale(0);
    transition: all 256ms ease-in;
}

.social-icons a:hover i {
    @include transformScale(2.2);
    color: #00B5F5;
    background: -webkit-linear-gradient(45deg, #00B5F5, #002A8F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 256ms ease-in;
}

@media only screen and (max-width: 1000px) {
    #contact {
        padding-bottom: 4em;
        background: #1c1d25;  
    }

    .contact-text {
        font-size: 1.8rem;
        text-align: center;
        padding: 3rem 1rem;
    }

    .footer-container {
        width: 100%;
        height: auto;
    }

    .name-container {
        font-size: 2rem;
        margin-top: 2rem;
    }

    .social-container {
        width: 100%;
    }

    .social-icons {
        margin: 1em 0em;
    }

    .social-icons a {
        display: grid;
        place-items: center;
        width: 40px;
        height: 40px;
    }  

    .social-icons li {
        display: inline-block;
        margin: 0.15em 0.6em;
        position: relative;
        font-size: 1em;
    }
}